import './App.css';
import SplashPage from './SplashPage';
import Description from './Description';
import Facts from './Facts';
import Gift from './Gift';

function App() {
  return (
    <div className="App">
      <SplashPage />
      <Description />
      <Facts />
      <Gift />
    </div>
  );
}

export default App;
