import { Card, Avatar } from 'antd';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

const items = [
    {
      title: 'Oncle Sim',
      content: '"I sometimes let him win at FIFA just to be a nice uncle. But he is getting too confident. Gonna fix that..."',
      image: 'oncle_sim.png',
    },
    {
      title: 'Maman',
      content: '"After dedicating 14 years to his intense educational work, I am very satisfied with the result"',
      image: 'maman.jpg',
    },
    {
      title: 'Papa',
      content: '"14 years of great experience. Looking forward to continuing this amazing adventure together"',
      image: 'papa.jpg',
    },
    {
      title: 'Nathan',
      content: '"A great FIFA, soccer, and tennis competitor. Highly recommended"',
      image: 'nathan.jpg',
    },
    {
      title: 'Matheo',
      content: '"I highly recommend him as brother. His services are available at a rate of 10€ per 2 hours. Cards and cash accepted. Free shipping as from 24 hours rent."',
      image: 'matheo.jpg',
    },
    {
      title: 'Mamy Joe',
      content: '"Who turned 14 on June 23th in 1986?"',
      image: 'mamyjoe.jpeg',
    },
    {
      title: 'Daddy',
      content: '"Wonderful grandson and probably the future best football commentator!"',
      image: 'daddy.jpg',
    },
    {
      title: 'Tata',
      content: '"He has the best bed in the world to accommodate a stranded marraconaire whale."',
      image: 'tata.jpg',
    },
    {
      title: 'Mamy',
      content: '"If one day they call you captain, it certainly won\'t be of a boat."',
      image: 'mamie.png',
    },
  ];

const FadeInTitle = styled.h2`
  font-family: "Ubuntu", serif;
  font-style: normal;
  color: #664BF7 !important;
  font-size: 60px;
  text-align: left;
  margin-bottom: 60px;
  text-align: center;

  @media only screen and (max-width: 1200px) {
    text-align: center;
    font-size: 30px;
  }
`;

const Facts = () => (
  <div style={{ padding: '40px 0 100px 0'}}>
    <div style={{ maxWidth: 960, margin: '0 auto' }}>
     <FadeInTitle>Hear it fom his family</FadeInTitle>
     <div className="grid-container">
    {items.map((item, index) => (
      <ScrollAnimation animateIn={index % 2 === 0 ? 'fadeInLeft' : 'fadeInRight'} duration={1} className="grid-item" key={index} animateOnce>
          <Card bordered={false}>
            <Card.Meta
              avatar={<Avatar src={item.image} size={64} />}
              title={item.title}
              description={item.content}
              style={{textAlign: 'left',  fontFamily: '"Playfair Display", sans-serif', fontSize:16}}
            />
          </Card>
      </ScrollAnimation>
    ))}
  </div>
    </div>
  </div>
);

export default Facts;
