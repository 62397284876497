// src/SplashPage.tsx
import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; transform: translate(0, 100px) }
  to { opacity: 1; transform: translate(0,0)}
`;

const SplashContainer = styled.div`
  display: flex;
  background: 
  linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%),
    url('mael-1.webp');
    background-size: cover;
    background-position: center;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
  padding: 0 50px;
`;

const FadeInText = styled.h1`
    animation: ${fadeIn} 1.5s ease-in-out;
    color: #fff !important;
    text-align: center;
    margin: 0;
    display: block;
    width: 100%;
    font-family: "Ubuntu";
    font-size: 6vw;
    line-height: .8;
    font-weight: 900;
    padding-bottom: 100px;

    @media only screen and (max-width: 2500px) {
      font-size: 8vw;
    }

    @media only screen and (max-width: 1200px) {
      font-size: 13vw;
      padding-bottom: 30px;
    }
`;



const SplashPage: React.FC = () => {
  return (
    <SplashContainer>
      <FadeInText>THE FUTURE LEGEND</FadeInText>
    </SplashContainer>
  );
};

export default SplashPage;
