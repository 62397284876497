import React from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import ScrollAnimation from 'react-animate-on-scroll';

const SplashContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  position: relative;
  min-height: 650px;
  max-width: 1300px;
  margin: 0 auto;

  @media only screen and (max-width: 1200px) {
    display: block;
    padding: 20px 20px 0;
  }
`;

const FadeInText = styled.p`
font-family: "Playfair Display", sans-serif;
  font-style: normal;
  font-size: 20px;
  text-align: left;
  color: #252525 !important;

`;


const FadeInTitle = styled.h2`
  font-family: "Ubuntu", serif;
  font-style: normal;
  color: #664BF7 !important;
  font-size: 60px;
  text-align: left;
  margin: 20px 0;

  @media only screen and (max-width: 1200px) {
    text-align: center;
    font-size: 30px;
  }

`;
const FadeInImage = styled.img`
height: 700px;
position: absolute;
top: -100px;
right: 0;
display: block;

@media only screen and (max-width: 1200px) {
    position: static;
    max-width: 500px;
    height: auto;
    margin: 0 auto;
  }

  @media only screen and (max-width: 500px) {
    max-width: 100%;
  }
`;


const CardContainer = styled.div`
  max-width: 700px;
  @media only screen and (max-width: 1200px) {
    max-width: none;
  }
`

const Description: React.FC = () => {
  return (
    <SplashContainer>
    <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce>
        <CardContainer>
    <Card title={ <FadeInTitle>Celebrates 14 years</FadeInTitle>} >
        <FadeInText>After leaving a lasting impression for 14 years, the next champion will need to live up to the expectations. For this special year, he will receive the perfect gift.</FadeInText>
    </Card>
    </CardContainer>
    </ScrollAnimation>
      <FadeInImage src="mael.png" alt="Special One" />
    </SplashContainer>
  );
};

export default Description;
