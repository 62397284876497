// @ts-nocheck 
import { Card, List } from "antd";
import { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from { opacity: 0; transform: translate(0, 100px) }
  to { opacity: 1; transform: translate(0,0)}
`;


const data = [
    'Regarding the UCL draw and if USG is qualified, we take the best match. (We hope Real Madrid)',
    'We take the train to move.',
    'Burgers from Uncle Richard mandatory.',
    'I chose the night movie!',
  ];
  


function launch() {
	console.log('launch')
    const present = document.querySelector('.present')
    present.onclick = () => present.classList.toggle('open')
	const canvas = document.querySelector('canvas')
	const ctx = canvas.getContext('2d')

	let width, height, lastNow
	let snowflakes
	let maxSnowflakes = 100

	function init() {
		snowflakes = []
		resize()
		render(lastNow = performance.now())
	}

	function render(now) {
		requestAnimationFrame(render)

		const elapsed = now - lastNow
		lastNow = now

		ctx.clearRect(0, 0, width, height)
		if (snowflakes.length < maxSnowflakes)
			snowflakes.push(new Snowflake())

		ctx.fillStyle = ctx.strokeStyle = '#FFD700'

		snowflakes.forEach(snowflake => snowflake.update(elapsed, now))
	}


	class Snowflake {
		constructor() {
			this.spawn()
		}

		spawn(anyY = false) {
			this.x = rand(0, width)
			this.y = anyY === true
				? rand(-50, height + 50)
				: rand(-50, -10)
			this.xVel = rand(-.05, .05)
			this.yVel = rand(.02, .1)
			this.size = rand(7, 12)
			this.sizeOsc = rand(.01, .5)
		}

		update(elapsed, now) {
			const xForce = rand(-.001, .001);

			if (Math.abs(this.xVel + xForce) < .075) {
				this.xVel += xForce
			}

			this.x += this.xVel * elapsed
			this.y += this.yVel * elapsed

			if (
				this.y - this.size > height ||
				this.x + this.size < 0 ||
				this.x - this.size > width
			) {
				this.spawn()
			}

			this.render()
		}

		render() {
			ctx.save()
			const { x, y, size } = this
			ctx.beginPath()
			ctx.arc(x, y, size * 0.2, 0, Math.PI * 2, false)
			ctx.fill()
			ctx.restore()
		}
	}

	const rand = (min, max) => min + Math.random() * (max - min)

	function resize() {
		width = canvas.width = window.innerWidth
		height = canvas.height = window.innerHeight
		maxSnowflakes = Math.max(width / 10, 100)
	}


	init()

}

const Container = styled.div`
animation: ${fadeIn} .3s ease-out;
`

const FadeInText = styled.p`
font-family: "Playfair", serif;
  font-style: normal;
  font-size: 20px;
  text-align: left;
  max-width: 700px;
  display: block;
`;

const FadeInTitle = styled.h2`
font-family: "Ubuntu", serif;
font-style: normal;
color: #664BF7 !important;
font-size: 60px;
text-align: left;
margin-bottom: 60px;
text-align: left;
padding: 0 20px;
@media only screen and (max-width: 1200px) {
    text-align: center;
    font-size: 30px;
  }

`;

const FadeInSubTitle = styled.h2`
font-family: "Ubuntu", serif;
font-style: normal;
color: #664BF7 !important;
font-size: 33px;
text-align: left;
margin-bottom: 60px;
text-align: left;
`;


const Gift = () => {
    const [hasOpen, setHasOpen] = useState(false);
	const present = useRef(null);
  useEffect(() => {
	if (present.current) {
		launch()
	}
  }, [])

    return  (   
     <div style={{ padding: '40px 0 100px 0', background: '#0F2145', position: 'relative', minHeight: 800}} className="gift">
        <div style={{ maxWidth: 960, margin: '0 auto' }}>
       <FadeInTitle>Enjoy the gift...</FadeInTitle> 
       </div>
       <canvas></canvas>

    { !hasOpen &&  
    <>

    <div ref={present} className="present" onClick={() => setTimeout(() => setHasOpen(true), 300)}>        
        <div className="rotate-container">
            <div className="bottom"></div>
            <div className="front"></div>
            <div className="left"></div>
            <div className="back"></div>
            <div className="right"></div>
            
            <div className="lid">
                <div className="lid-top"></div>
                <div className="lid-front"></div>
                <div className="lid-left"></div>
                <div className="lid-back"></div>
                <div className="lid-right"></div>
            </div>
        </div>
    
    </div> </>}
    {hasOpen &&   <div style={{ maxWidth: 960, margin: '0 auto' }}>
        <Container>
    <Card 
        cover={
            <img
              alt="example"
              src="bruges.jpeg"
            />
          }
    title={ <FadeInSubTitle>Let's watch the Champions League with Oncle Sim </FadeInSubTitle>}>
        <FadeInText>You’re invited at Oncle Sim's house to spend 3 days with him, during those days we’re gonna watch a champions league match at either USG or Bruges and make a visit of a city in Belgium. The gift is from: Oncle Sim, Mamy Joe, Daddy</FadeInText>
        <FadeInText>Practical details:</FadeInText>
        <List

            size="small"
            bordered
            renderItem={(item) => <List.Item>{item}</List.Item>}
            dataSource={data}
            style={{fontFamily: '"Playfair Display", sans-serif', textAlign: 'left'}}
        />
              <FadeInTitle>Happy Birthday Maël</FadeInTitle>
    </Card>
    </Container>
    </div>}
  
    </div>
    )
}

export default Gift